import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ElevationProfile from "../components/geo-related/eleveation-profile"
import Map from "../components/geo-related/map"
import Img from "gatsby-image"
import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"
import Hops from "../components/hops"
import { Column, ColumnIsOneThird, Columns } from "../components/styled-components"
import TourCard, { ITourCardFragment } from "../components/tour-card"
import AccoSettings from "../components/acco-settings"
import { postTourView } from "../components/analytics"
import Books, { IBookFragment } from "../components/books"
import FacilitySettings from "../components/facilities-settings"
import { useState } from "react"
import { IBookingHotel } from "../shared-interfaces/booking-hotel"
import { IActivity } from "../shared-interfaces/activity"
import { IRailwayStation } from "../shared-interfaces/railway-station"
import { IHop } from "../shared-interfaces/hop"

interface Data {
  contentfulTrip: {
    fields: {
      yovelinoSlug: string;
      fullcontentHtml: string;
      excerptHtml: string;
    };
    title: string;
    level: {
      name: string;
    }
    p: string;
    mainPicture: {
      localFile: {
        childImageSharp: {
          fluid: any;
        }
      }
    };
    lengthInKm: number;
    categories: {
      name: string;
    }[];
    countries: {
      name: string;
      fields: {
        countrySlug: string
      }
    }[];
    regions: {
      name: string;
      fields: {
        regionSlug: string;
      }
    }[];
    relatedTours: ITourCardFragment[];
    trackFile: {
      childrenBookingHotels: IBookingHotel[];
      childrenActivities: IActivity[];
    };
    books: IBookFragment[];
  };
}

const kAccoType = "t"

const sectionCss = css`
  max-width: 790px;
  padding: 0 ${rhythm(0.75)};
  margin: auto;
`

const filterByAccoType = (allAccos: IBookingHotel[], accoType: number) => {
  return accoType === 0
    ? allAccos
    : allAccos.filter(acco => acco[kAccoType] === accoType)
}

const filterByFacilities = (filteredAccos: IBookingHotel[], selectedFacilities: number) => {
  if (selectedFacilities === 0) {
    return filteredAccos
  }
  return filteredAccos.filter((acco) => (acco.f & selectedFacilities) === selectedFacilities)
}

type Page = {
  data: Data;
  pageContext: {
    track: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>;
    hopsWithAccoIds: IHop[];
    kilometerMarkerCoords: number[][];
    overallFacilities: number;
  };
}

export default ({ data, pageContext }: Page) => {
  const trip = data.contentfulTrip
  const track = pageContext.track
  const hopsWithAccoIds = pageContext.hopsWithAccoIds
  const allAccos = trip.trackFile.childrenBookingHotels || []
  const allActivies = trip.trackFile.childrenActivities || []
  const allStations: IRailwayStation[] = track.features[0].properties.railwaystations || []
  const hasAccos = allAccos.length > 0
  const kilometerMarkerCoords = pageContext.kilometerMarkerCoords
  const overallFacilities = pageContext.overallFacilities

  const [activeGroup, setActiveGroup] = useState(0) // 0-Accomodations, 1-Activities, 2-stations
  const [showPrice, setShowPrice] = useState(false)
  const [accoType, setAccoType] = useState<number>(0) // 0-all, 1-hotel, 2-fereinwohnung, 3-pension
  const [activities] = useState(() => allActivies)
  const [stations] = useState<IRailwayStation[]>(() => allStations)
  const [selectedFacilities, setSelectedFacilities] = useState(0)

  const filteredAccos = filterByFacilities(filterByAccoType(allAccos, accoType), selectedFacilities)

  postTourView(trip.fields.yovelinoSlug, accoType, showPrice, activeGroup)

  return (
    <Layout>
      <SEO title={trip.title}/>
      <div css={sectionCss}>
        <div css={css`
            position: relative;
            margin: 0;
          `}>
          <Img fluid={trip.mainPicture.localFile.childImageSharp.fluid}/>
          <div css={css`
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 50%;
              background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.4));
            `}/>
          <div css={css`
              position: absolute;
              bottom: 0;
              right: 0;
              font-size: ${rhythm(0.35)};
              padding-right: ${rhythm(0.25)};
              color: white;
            `}>&copy; {trip.p ? trip.p + " / Fotolia" : "yovelino"}
          </div>
          <h1 css={css`
              position: absolute;
              color: white;
              bottom: ${rhythm(0.75)};
              padding-left: ${rhythm(0.5)};
            `}>
            {trip.title}
          </h1>
        </div>

        <Columns>
          <Column>{trip.categories[0].name} {trip.lengthInKm} km</Column>
          <Column css={css`
            text-align: right;
          `}>{trip.level.name}</Column>
        </Columns>

        <div className="tags are-medium">
          {trip.countries.map(c => (
            <span className="tag is-info is-light" key={c.name}><Link className="link__internal"
                                                                      to={c.fields.countrySlug}>{c.name}</Link></span>
          ))}
          {trip.regions.map(r => (
            <span className="tag is-info is-light" key={r.name}><Link className="link__internal"
                                                                      to={r.fields.regionSlug}>{r.name}</Link></span>
          ))}
        </div>

        <div dangerouslySetInnerHTML={{ __html: trip.fields.excerptHtml }}/>

        {track &&
        <section>
          <h2>Streckenverlauf {trip.title}</h2>
          <div>
            <div className="buttons has-addons">
              {hasAccos && (
                <button onClick={() => setActiveGroup(0)}
                        className={`button is-small ${activeGroup === 0 ? "is-selected is-info" : ""} `}>Unterkünfte
                </button>
              )}
              {activities.length > 0 && (
                <button onClick={() => setActiveGroup(1)}
                        className={`button is-small ${activeGroup === 1 ? "is-selected is-info" : ""} `}>Aktivitäten
                </button>
              )}
              {stations.length > 0 && (
                <button onClick={() => setActiveGroup(2)}
                        className={`button is-small ${activeGroup === 2 ? "is-selected is-info" : ""} `}>Bahnhöfe
                </button>
              )}
            </div>
          </div>
          <AccoSettings showPrice={showPrice} setShowPrice={setShowPrice} accoType={accoType}
                        accosChanged={setAccoType} activeGroup={activeGroup}/>

          {
            activeGroup === 0 && <FacilitySettings availableFacilities={overallFacilities}
                                                   selectedFacilities={selectedFacilities}
                                                   onCangedSelectedFacilities={setSelectedFacilities}/>
          }

          {
            (typeof window !== "undefined" && track)
              ? (
                <Map track={track}
                     hops={hopsWithAccoIds}
                     accos={activeGroup === 0 ? filteredAccos : null}
                     showPrice={showPrice}
                     activities={activeGroup === 1 ? activities : null}
                     stations={activeGroup === 2 ? stations : null}
                     tourSlug={trip.fields.yovelinoSlug}
                     kilometerMarkerCoords={kilometerMarkerCoords}/>
              )
              : null
          }


        </section>
        }
        <p/>
        {track && <ElevationProfile track={track}/>}
        <p/>
        {hopsWithAccoIds && hopsWithAccoIds.length > 0 && (
          <section>
            <h2>Unser Tour-Vorschlag {trip.title}</h2>
            <AccoSettings showPrice={showPrice}
                          setShowPrice={setShowPrice}
                          accoType={accoType}
                          accosChanged={setAccoType}
                          activeGroup={activeGroup}
                          disablePriceSelection={true}/>

            <FacilitySettings availableFacilities={overallFacilities}
                              selectedFacilities={selectedFacilities}
                              onCangedSelectedFacilities={setSelectedFacilities}/>

            <Hops hops={hopsWithAccoIds} accos={filteredAccos} yovelinoSlug={trip.fields.yovelinoSlug}/>
          </section>
        )}
        <section>
          <h2>Verlauf & Etappen {trip.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: trip.fields.fullcontentHtml }}/>
        </section>
        {(trip.books || []).length > 0 && (<Books books={trip.books}/>)}
        {
          (trip.relatedTours || []).length > 0 &&
          <section css={css`
            margin-bottom: ${rhythm(2)}
          `}>
            <h2>Weitere Touren entdecken</h2>
            <Columns>
              {(trip.relatedTours || []).map(tour => (
                <ColumnIsOneThird key={tour.title}>
                  <TourCard tour={tour}/>
                </ColumnIsOneThird>
              ))}
            </Columns>
          </section>
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
    query($yovelinoSlug: String!) {
        contentfulTrip(fields: { yovelinoSlug: { eq: $yovelinoSlug } }) {
            fields {
                yovelinoSlug
                fullcontentHtml
                excerptHtml
            }
            title
            level {
                name
            }
            p:nameOfFotoliaPhotographer
            mainPicture {
                localFile{
                    childImageSharp {
                        fluid(maxWidth: 500, maxHeight: 320) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            lengthInKm
            categories {
                name
            }
            countries {
                name
                fields {
                    countrySlug
                }
            }
            regions {
                name
                fields {
                    regionSlug
                }
            }
            relatedTours {
                ...TourCardFragment
            }
            trackFile {
                childrenBookingHotels {
                    _id
                    ufi
                    t
                    hotel_url
                    minrate
                    name
                    p_url
                    sl
                    rating
                    stars
                    f:facilities
                    latLng
                }
                childrenActivities {
                    url
                    title
                    imageUrl
                    rating
                    reviewCount
                    minimumPrice
                    certified
                    latLng
                }
            }
            books:amazonBooks {
                ...BookFragment
            }
        }
    }
`

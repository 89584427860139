import { useState } from "react"
import * as PropTypes from "prop-types"
import ElevationInteractiveProfile from "./elevation-interactive-profile"
import { css } from "@emotion/react"
import { rhythm } from "../../utils/typography"
import VisibilitySensor from "react-visibility-sensor"
import * as React from "react"

const levelCss = css`
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 769px), print {
    display: flex;
  }
`

const levelTitle = css`
  font-size: ${rhythm(0.75)};
  margin-bottom: 0;
  color: #363636;
  font-weight: 600;
  line-height: ${rhythm(1.25)};
`

const levelItem = css`
  align-items: center;
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;  
  text-align: center;
  &:not(:last-child) {
    @media screen and (max-width: 768px) {
      margin-bottom: ${rhythm(0.5)};
    }
  }
`

const headingCss = css`
  display: block;
  font-size: ${rhythm(0.5)};
  letter-spacing: 1px;
  margin-bottom: ${rhythm(0.25)};
  text-transform: uppercase;
`

type Props = {
  statistic: {
    distanceOverAll: number;
    minElevation: number;
    maxElevation: number;
    upMeters: number;
    downMeters: number;
  }
}

const RouteStatistic = ({ statistic }: Props) => {
  return (
    <div>
      <nav css={levelCss}>
        <div css={levelItem}>
          <div>
            <p css={headingCss}>Länge</p>
            <p css={levelTitle}>{Math.round(statistic.distanceOverAll)} km</p>
          </div>
        </div>
        <div css={levelItem}>
          <div>
            <p css={headingCss}>Tiefster Punkt</p>
            <p css={levelTitle}>{Math.round(statistic.minElevation)} m</p>
          </div>
        </div>
        <div css={levelItem}>
          <div>
            <p css={headingCss}>Höchster Punkt</p>
            <p css={levelTitle}>{Math.round(statistic.maxElevation)} m</p>
          </div>
        </div>
        <div css={levelItem}>
          <div>
            <p css={headingCss}>Bergauf</p>
            <p css={levelTitle}>{Math.round(statistic.upMeters)} m</p>
          </div>
        </div>
        <div css={levelItem}>
          <div>
            <p css={headingCss}>Bergab</p>
            <p css={levelTitle}>{Math.round(statistic.downMeters)} m</p>
          </div>
        </div>
      </nav>
    </div>
  )
}

RouteStatistic.propTypes = {
  statistic: PropTypes.object,
}

type PropsElevationProfile = {
  track: GeoJSON.FeatureCollection<GeoJSON.GeometryObject>;
}


type EleveationProfile = {
  lng: number;
  lat: number;
  ele: number;
  distanceFromStart: number;
}

const ElevationProfile = ({ track }: PropsElevationProfile) => {

  const [elevations, setElevations] = useState<EleveationProfile[]>([])

  const onVisibilityChange = (visible: boolean) => {
    if (visible && elevations.length === 0) {
      const result: EleveationProfile[] = []
      import("geo-tools").then((geoLib) => {
        geoLib.GeoTools.processOnEachEntry(track, (coordinate) => {
          result.push(({
            lng: coordinate[0],
            lat: coordinate[1],
            ele: coordinate[2],
            distanceFromStart: coordinate[3],
          }))
        })
        setElevations(result)
      })
    }
  }

  const routeStatistics = track.features[0].properties.routestatistics
  return (
    <VisibilitySensor onChange={onVisibilityChange} partialVisibility={true}>
      <section>
        <h2>Höhenprofil {track.features[0].properties.name}</h2>
        {elevations.length > 0 && (<ElevationInteractiveProfile elevations={elevations}/>)}
        <RouteStatistic statistic={routeStatistics}/>
      </section>
    </VisibilitySensor>
  )
}

export default React.memo(ElevationProfile)

const rad = (Math.PI / 180)
const R = 6378.137 // earth radius in km

exports.calculateDistanceInKilometerForTwoPoints = (from/* number[]*/, to /* number[]*/) => {
  const lat1 = from[1]
  const lon1 = from[0]
  const lat2 = to[1]
  const lon2 = to[0]
  const dLat = (lat2 - lat1) * rad
  const dLon = (lon2 - lon1) * rad
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * rad) * Math.cos(lat2 * rad) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c // the kilometers between two points
}

import * as React from "react"
import { css } from "@emotion/react"
import * as PropTypes from "prop-types"

type Props = {
  showPrice: boolean;
  setShowPrice: (boolean) => void;
  accoType: number;
  accosChanged: (number) => void;
  activeGroup: number;
  disablePriceSelection?: boolean;
}

const AccoSettings = (
  { showPrice, setShowPrice, accoType, accosChanged, activeGroup, disablePriceSelection = false }: Props) => {

  return (
    <div className="buttons has-addons" css={css`
            margin-top: 0.5em;
            margin-bottom: 0;
            justify-content: space-between;
          `}>
      <div>
        {!disablePriceSelection && (
          <button onClick={() => setShowPrice(!showPrice)}
                  className={`button is-small is-light ${showPrice ? "is-selected is-info" : ""} `}>Preise
          </button>)}
      </div>
      {activeGroup === 0 && (
        <div>
          <button onClick={() => accosChanged(0)}
                  className={`button is-small is-light ${accoType === 0 ? "is-selected is-info" : ""} `}>Alle
          </button>
          <button onClick={() => accosChanged(1)}
                  className={`button is-small is-light ${accoType === 1 ? "is-selected is-info" : ""} `}>Hotel
          </button>
          <button onClick={() => accosChanged(2)}
                  className={`button is-small is-light ${accoType === 2 ? "is-selected is-info" : ""} `}>Ferienwohnung
          </button>
          <button onClick={() => accosChanged(3)}
                  className={`button is-small is-light ${accoType === 3 ? "is-selected is-info" : ""} `}>Pension
          </button>
        </div>
      )}
    </div>
  )
}

AccoSettings.propTypes = {
  activeGroup: PropTypes.number,
  showPrice: PropTypes.bool,
  setShowPrice: PropTypes.func,
  accoType: PropTypes.number,
  accosChanged: PropTypes.func,
  disablePriceSelection: PropTypes.bool,
}

export default React.memo(AccoSettings)

import * as React from "react"
import BookingLink from "./booking/booking-link"
import { decodePhotoUrl } from "../utils/photo_url"
import { calculateDistanceInKilometerForTwoPoints } from "../utils/geo"
import { IBookingHotel } from "../shared-interfaces/booking-hotel"
import { IHop } from "../shared-interfaces/hop"


const ratingFormatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 1,
})

const distanceFormatter = new Intl.NumberFormat("de-DE", {
  maximumFractionDigits: 1,
})

type Props = {
  hops: IHop[];
  accos: IBookingHotel[];
  yovelinoSlug: string
}

const Hops = ({ hops, accos, yovelinoSlug }: Props) => {

  return (
    <div className="hops">
      <ul className="hops-list">
        {hops.map((hop, idx) => {

          // collect up to 5 accos form the hops list  - the accosNearBy are sorted by distance
          const filteredAccosNearBy: IBookingHotel[] = []
          for (let accoId of hop.accosNearBy) {
            const acco = accos.find(acco => acco._id === accoId)
            if (acco) {
              filteredAccosNearBy.push(acco)
              if (filteredAccosNearBy.length === 5) {
                break
              }
            }
          }

          return (
            <li key={idx} className="hops-item">
              <div className="hop">
                <h3 className="hop-title"> {idx === 0 ? "Start in " : ""} {hop.name}</h3>
                <ul className="hotel-list">
                  {filteredAccosNearBy.map(acco => {

                    const lngLat = [acco.latLng[1], acco.latLng[0]]
                    const distanceFromHop = calculateDistanceInKilometerForTwoPoints(hop.lngLat, lngLat)
                    const ratingFormatted = acco.rating ? ratingFormatter.format(acco.rating) : ""

                    return (
                      <li className="hotel" key={acco._id}>
                        <div className="hotel-table">
                          <div className="hotel-cell">
                            <div className="hotel-image">
                              <img src={decodePhotoUrl(acco.p_url)} loading="lazy" alt={acco.name}/>
                            </div>
                          </div>
                          <div className="hotel-details">
                            <div className={`hotel-rating ${ratingFormatted ? "" : "no-rating"}`}>{acco.rating}</div>
                            <div className="hotel-name">
                              <a className="link__internal"
                                 href={`${yovelinoSlug}/${acco.sl}`}>{acco.name}</a>
                            </div>
                            <div className={`hotel-stars ${acco.stars ? ("hotel-stars" + acco.stars) : ""}`}/>
                            <div className="hotel-minprice">{acco.minrate ? "ab " + acco.minrate + " €" : ""}</div>
                            <div className="hotel-distance">Entfernung {distanceFormatter.format(distanceFromHop)} km
                            </div>
                            <div className="hotel-action">
                              <BookingLink acco={acco} tourSlug={yovelinoSlug} withIcon={true}/>
                            </div>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="hop-distance">{hop.km ? hop.km.toLocaleString() : ""}</div>
              <div className="hop-distance-km">{hop.km ? " km" : ""}</div>
            </li>

          )
        })}
      </ul>
    </div>
  )
}

export default React.memo(Hops)

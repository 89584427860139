import * as React from "react"
import { css } from "@emotion/react"
import { facilities } from "../consts/facilities"

type Props = {
  availableFacilities: number;
  selectedFacilities: number;
  onCangedSelectedFacilities: (number) => void;
}

const FacilitySettings = ({ availableFacilities, selectedFacilities, onCangedSelectedFacilities }: Props) => {
  if (!availableFacilities) {
    return <></>
  }

  return (
    <div className="buttons has-addons" css={css`
                margin-top: 0.5em;
                margin-bottom: 0;
                justify-content: space-between;
              `}>
      {
        facilities
          .filter(facility => (availableFacilities & facility.facility) === facility.facility)
          .map(facility => {
              const selected = (selectedFacilities & facility.facility) === facility.facility
              return (
                <button
                  onClick={() => onCangedSelectedFacilities(selectedFacilities ^ facility.facility)}
                  className={`button is-small  ${selected ? "is-active is-info" : ""} `}
                  key={facility.label}>
                          <span className="icon">
                            <i css={css` background-color: ${selected ? "#fff" : "#07c"};`}
                               className={`icon ${facility.icon}`}/>
                           </span>
                  <span>{facility.label}</span>
                </button>)
            },
          )
      }
    </div>
  )
}

export default React.memo(FacilitySettings as any)

import * as React from "react"
import { useEffect, useRef } from "react"
import "./elevation-interactive-profile.scss"

type Props = {
  elevations: any
}

const ElevationInteractiveProfile = ({ elevations }: Props) => {

  const svgEl = useRef<SVGSVGElement>(null)

  useEffect(() => {
    import("geo-tools").then((geoTools) => {
      geoTools.drawElevationOnSvgElementId(elevations, svgEl.current!.id)
    })
  })

  return (
    <div>
      <svg
        ref={svgEl}
        id="svg-elevation"
        width="100%"
        height="160px"
        viewBox="0 0 780 160"
        preserveAspectRatio="xMinYMid meet"/>
    </div>
  )
}

export default React.memo(ElevationInteractiveProfile)
